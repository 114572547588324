.header {
  margin-top: -20px;
  padding: 1.25rem;
  background-color: white;
  box-shadow: 0 7px 14px 0 rgb(59 65 94 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
  border-radius: 0.375rem;
  margin-bottom: 16px;
  position: relative;

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .actions {
    z-index: 100;
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../images/page_header_corner.png');
    background-size: contain;
    background-position: right;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    background-repeat: no-repeat;
  }

  .page-header-title {
    margin: 0;
    font-size: 25px;
    color: #344050;
    font-weight: 500;
    font-family: "Poppins" !important;
  }
}